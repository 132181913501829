<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
       <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="请输入内容" v-model="getInfo.info">
            <el-button
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="8"> </el-col>
          <el-tooltip class="item" effect="dark"  content="增加等级" placement="top-start">
            <el-button  @click="addLevel" type="primary">增加等级</el-button>
        </el-tooltip>
      </el-row>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        v-if="tableData"
      >
      <el-table-column align="center" width="100px" prop="id" label="ID"></el-table-column>
        <el-table-column align="center"  prop="label" label="会员等级"></el-table-column>
        <el-table-column align="center"  prop="name" label="会员级别"></el-table-column>
        <el-table-column align="center"  prop="family" label="家庭消费金额"></el-table-column>
        <el-table-column align="center"  prop="company" label="机构消费金额"></el-table-column>
        <el-table-column align="center"  prop="discount" label="折扣"></el-table-column>
        <el-table-column align="center"  prop="sort" label="排序"></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
                 <el-tooltip class="item" effect="dark"  content="修改等级" placement="top-start">
            <el-button icon="el-icon-edit" type="primary" @click="updataLevel(scope.row)" ></el-button>
          </el-tooltip>
             <el-tooltip class="item" effect="dark"  content="删除等级" placement="top-start">
            <el-button icon="el-icon-delete" type="danger" @click="delLevel(scope.row.id)" ></el-button>
          </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="exp.num"
      >
      </el-pagination>
    </el-card>
        <!-- 删除提示框 -->
    <el-dialog
  title="提示"
  :visible.sync="dialogVisible"
  width="30%"
  :before-close="handleClose">
  <span>是否确定删除该等级?</span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="refuse">取 消</el-button>
    <el-button type="primary" @click="consent">确 定</el-button>
  </span>
</el-dialog>
<!-- 增加等级 -->
<el-dialog
      :visible.sync="AddDialogFormVisible"
      :append-to-body="true"
      :before-close="AddhandleClose"
    >
      <div slot="title" class="dialog-footer">
        <div class="title" v-show="openState">添加等级</div>
        <div class="title" v-show="!openState">修改等级</div>
      </div>
      <div class="diaBox">
                 <el-form  :model="addLevelForm" label-width="100px" :rules="rules" ref="ruleForm">
       <el-form-item label="ID"  v-if="!openState">
         <el-input disabled v-model="addLevelForm.id" placeholder="" style="width:200px;"></el-input>
        </el-form-item>
        <el-form-item label="会员等级" prop="Label">
         <el-input v-model="addLevelForm.label" placeholder="请输入会员等级(必填)" style="width:200px;"></el-input>
        </el-form-item>
        <el-form-item label="会员级别" prop="Name">
         <el-input v-model="addLevelForm.name" placeholder="请输入会员级别(必填)" style="width:200px;"></el-input>
        </el-form-item>
        <el-form-item label="家庭消费金额" prop="Family">
         <el-input v-model="addLevelForm.family" placeholder="请输入家庭消费金额(必填)" style="width:200px;"></el-input>
        </el-form-item>
        <el-form-item label="机构消费金额" prop="Company">
         <el-input v-model="addLevelForm.company" placeholder="请输入机构消费金额(必填)" style="width:200px;"></el-input>
        </el-form-item>
        <el-form-item label="折扣" prop="Discount">
         <el-input v-model="addLevelForm.discount" placeholder="请输入折扣(必填)" style="width:200px;"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="Status">
          <el-select v-model="addLevelForm.sort" placeholder="请选择排序(必选)"  style="width:200px;">
      <el-option label="0" value="0"></el-option>
      <el-option label="1" value="1"></el-option>
    </el-select>
        </el-form-item>
      </el-form>
      </div>
      
      <div slot="footer" class="dialog-footer">
        <el-button @click="addExpCancel">取 消</el-button>
        <el-button type="primary" @click="setAdd('ruleForm')" v-show="openState"
          >添 加</el-button
        >
        <el-button type="primary" @click="setEdit" v-show="!openState"
          >修 改</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { PostLevelList,PostAddLevel,PostUpdataLevel,PostDelLevel} from "@/api/Family/level/level.js";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    var validateLabel = (rule, value, callback) => {
        if (this.addLevelForm.label === '') {
          callback(new Error('会员等级不能为空！'));
        } else {
          callback();
        }
      };
       var validateName = (rule, value, callback) => {
        if (this.addLevelForm.name === '') {
          callback(new Error('会员级别不能为空！'));
        } else {
          callback();
        }
      };
       var validateCompany = (rule, value, callback) => {
        if (this.addLevelForm.company === '') {
          callback(new Error('机构消费不能为空！'));
        } else {
          callback();
        }
      };
       var validateFamily = (rule, value, callback) => {
        if (this.addLevelForm.family === '') {
          callback(new Error('家庭消费金额不能为空！'));
        } else {
          callback();
        }
      };
       var validateDiscount = (rule, value, callback) => {
        if (this.addLevelForm.discount === '') {
          callback(new Error('折扣不能为空！'));
        } else {
          callback();
        }
      };
       var validateStatus = (rule, value, callback) => {
        if (this.addLevelForm.sort === '') {
          callback(new Error('请选择排序！'));
        } else {
          callback();
        }
      };
    //这里存放数据
    return {
      rules: {
          Label: [
            { validator: validateLabel, trigger: 'blur' }
          ],
           Name: [
            { validator: validateName, trigger: 'blur' }
          ],
           Family: [
            {  validator: validateFamily, trigger: 'change'  }
          ],
           Company: [
            { validator: validateCompany, trigger: 'blur' }
          ],
          Discount: [
            { validator: validateDiscount, trigger: 'blur' }
          ],
           Status: [
            { validator: validateStatus, trigger: 'blur' }
          ],
        },
      addLevelForm:{
        id:'',
        label:'',
        name:'',
        family:'',
        company:'',
        discount:'',
        sort:'',
    },
      //删除等级模态框
      dialogVisible:false,
       //增加等级模态框
      AddDialogFormVisible:false,
      openState:true,
      dialogFalimyID:'',
      //总条数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page_code: 1,
        page_num: 15,
      },
      //表格数据
      tableData: [],
      //家庭Id
      familyId:"",//家庭ID
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    setAdd(formName){
      var that=this;
       this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$confirm('确认增加？')
          .then(_ => {
                console.log(this.addLevelForm);
                this.PostAddLevel(this.addLevelForm);
          }).catch((err)=>{
            console.log(err)
          })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      
       console.log("确认增加")
    },
    setEdit(){
        console.log("确认修改")
         this.$confirm('确认修改？')
          .then(_ => {
                console.log("确定")
                var data={
                  id:this.ExpID
                }
                console.log(this.addLevelForm)
                this.PostUpdataLevel(this.addLevelForm);
                this.AddDialogFormVisible=false;
          }).catch(()=>{
              console.log("取消")
              this.dialogVisible=false;
          })
        
    },
     AddhandleClose(done){
          this.$confirm('确认关闭？')
          .then(_ => {
            done();
              this.addLevelForm={
        id:'',
        label:'',
        name:'',
        family:'',
        company:'',
        discount:'',
        sort:'',
    }
          })
          .catch(_ => {});
      },
    addLevel(){
       console.log("增加等级")
       this.AddDialogFormVisible=true;
       this.openState=true;
    },
    updataLevel(item){
       console.log("修改等级",item)
       this.addLevelForm={
        id:item.id,
        label:item.label,
        name:item.name,
        family:item.family,
        company:item.company,
        discount:item.discount,
        sort:item.sort,
    }
         this.AddDialogFormVisible=true;
         this.openState=false;
         this.ExpID=item.id;
    },
    delLevel(id){
       console.log("删除等级")
       this.ExpID=id;
         console.log(id);
         this.dialogVisible=true;
    },
     //取消删除等级
    refuse(){
          this.dialogVisible=false;
          console.log("取消删除兑换卷")       
    },
     //同意删除等级
    consent(){
         this.$confirm('确认删除？')
          .then(_ => {
                console.log("确定")
                var data={
                  id:this.ExpID
                }
                this.PostDelLevel(data);
                this.dialogVisible=false;
          }).catch(()=>{
              console.log("取消")
              this.dialogVisible=false;
          })
    },
    handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
    //查看用户消费记录
    consumer(id,userid){
         this.$router.push({
        path:"/family/consume",
        query:{
          id,
          userid
        }
      })    
    },
    //修改每页数量
    sizeChange(value) {
      this.getInfo.page_num = value;
      var data={
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num
      
    }
    console.log(data);
    this.tableData=[]
    this.PostLevelList(data);
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
      var data={
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num
    }
    console.log(data);
    this.tableData=[]
    this.PostLevelList(data);

    },
     //取消增加等级模态框
    addExpCancel(){
          this.AddDialogFormVisible=false;
              this.addLevelForm={
        id:'',
        label:'',
        name:'',
        family:'',
        company:'',
        discount:'',
        sort:'',
    }
    },
    /**-----------------网络请求生命周期函数--------------------- */
    //修改等级
    PostUpdataLevel(data){
      var that=this;
        let res=PostUpdataLevel(data);
        res.then((res)=>{
          console.log(res);
          if(res.data.code==1){
              this.$message({
          message: res.data.message,
          type: 'success'
        });
         var data={
      page:that.getInfo.page_code,
      list_num:that.getInfo.page_num
    }
        this.addLevelForm={
        id:'',
        label:'',
        name:'',
        family:'',
        company:'',
        discount:'',
        sort:'',
    }
    this.tableData=[]
    that.PostLevelList(data);
          }else{
           this.$message.error(res.data.message);
        }
        }).catch((err)=>{
             console.log(err)
        })
    },
    //增加等级
     PostAddLevel(data){
      console.log(data)
       let res= PostAddLevel(data);
       res.then((res)=>{
              if(res.data.code==1){
              this.$message({
          message: res.message,
          type: 'success'
        });
         var data={
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num
    }
    this.tableData=[]
    this.PostLevelList(data);
    this.AddDialogFormVisible=false;
   this.addLevelForm={
        id:'',
        label:'',
        name:'',
        family:'',
        company:'',
        discount:'',
        sort:'',
    }
        }else{
           this.$message.error(res.data.message);
        }
       }).catch((err)=>{
             console.log(err);
       })
    },
    //等级设置列表
    async PostLevelList(data){
        let res=await PostLevelList(data);
        if(res.data.code==1){
          this.tableData=this.tableData.concat(res.data.data.data);
        }else{
           this.$message.error(res.data.message);
        }
        console.log(res.data);
    },
    //删除等级
    PostDelLevel(data){
      var that=this;
        let res=PostDelLevel(data);
        res.then((res)=>{
          console.log(res);
          if(res.data.code==1){
              this.$message({
          message: res.data.message,
          type: 'success'
        });
         var data={
      page:that.getInfo.page_code,
      list_num:that.getInfo.page_num
    }
    this.tableData=[]
    that.PostLevelList(data);
          }
          
            console.log(res.data)
        }).catch((err)=>{
             console.log(err)
        })
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    var data={
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num
    }
    this.PostLevelList(data);
  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.diaBox{
  display: flex;
  justify-content: center;
}
</style>