import { post } from '@/utils/request'

export function PostLevelList(data) {
    return post('admin/level/list', data)
}
export function PostAddLevel(data) {
    return post('admin/level/add', data)
}
export function PostUpdataLevel(data) {
    return post('admin/level/edit', data)
}
export function PostDelLevel(data) {
    return post('admin/level/del', data)
}
